.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.GameFrame {
  padding: 2px;
  width: 765px;
  margin: auto;
  vertical-align: middle;
  background-color: var(--backgroundcolor);
  background-repeat: repeat;
}

.Game {
  min-width: 800px;
  padding-top: 15px;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  background-image: var(--backgroundimggrad), var(--backgroundimg);
  background-position: top;
  background-repeat: repeat-x, repeat;
  position: absolute;
  min-height: 100%;
  box-sizing: border-box;
}

.sideBarGame {
  width: 150px;
  float: left;
  padding: 8px;
}

.header {
  width: 120px;
  color: #ffebd1;
  background-color: #efac71;
  margin: 5px 5px 10px 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
}

.elementOfMenu {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
  padding-left: 6px;
  margin: 0px;
  border-collapse: collapse;
}

.elementOfMenu:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.clickableText {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
  padding-top: 6px;
  margin: 0px;
  border-collapse: collapse;
}

.clickableText:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.alignCloudNotify,
.alignHelpNotify {
  display: inline-flex;
  align-items: center;
}

.elementOfMenuRed {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: RED;
  font-weight: bold;
  text-decoration: none;
  font-size: 10pt;
  padding-left: 6px;
  margin: 0px;
  border-collapse: collapse;
}

.elementOfMenuRed:hover {
  text-decoration: underline;
  color: RED;
  cursor: pointer;
}

.breakLineMenu {
  width: 1px;
  height: 1px;
}

.nuageTranslate {
  transform: translate(0px, 7px);
}

.pageCategoryHeader {
  width: 592px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
  transform: translate(-13px, 0px);
}

.pageCategoryHeaderBordeciel {
  width: 582px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
  transform: translate(-13px, 0px);
}

.pageCategoryHeaderHermit {
  width: 592px;
  height: 23px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 11pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 2px;
  transform: translate(-13px, 0px);
}

.footer1Game {
  padding-left: 220px;
  padding-top: 40px;
}

.footer2Game {
  text-decoration: none;
  text-align: center;
  vertical-align: bottom;
  padding-right: 8px;
  font-size: 8pt;
  text-align: center;
  color: #c37253;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 8pt;
  border-collapse: collapse;
}

.menuTitleGame {
  width: 140px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
  color: #efac71;
  font-weight: bold;
  font-size: 11pt;
  line-height: 11pt;
  border-bottom: 3px solid #efac71;
  margin-right: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.menuTitlePage {
  width: 140px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
  color: #efac71;
  font-weight: bold;
  font-size: 11pt;
  line-height: 11pt;
  margin-right: 5px;
}

.dcGame {
  color: #c37253;
  font-weight: bold;
  padding-left: 3px;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  vertical-align: middle;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.dcGame:hover {
  text-decoration: underline;
  cursor: pointer;
}

.help {
  color: red;
  font-weight: bold;
  padding-left: 3px;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  vertical-align: middle;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.help:hover {
  text-decoration: underline;
  cursor: pointer;
}

.detailImageBottomMenu {
  padding-left: 3px;
  vertical-align: middle;
}

.detailImageBottomMenu:hover {
  cursor: pointer;
}

.smallBox {
  width: 150px;
  height: 25px;
}

.contenuPrincipal {
  padding-top: 8px;
  width: 600px !important;
}

.principal {
  display: flex;
  height: complex;
}

.flexDivs {
  display: flex;
  flex-direction: row;
  margin-bottom: 55px;
}

.flexDivsIrma {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}

.displayFusions {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}

.expandLink {
  padding-left: 4px;
  padding-right: 0px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border: 2px solid #ffebd1;
  background: transparent;
  color: #c37253;
  font-weight: bold;
  font-size: 9pt;
}

.expandLinkPage {
  border: 2px solid #ffebd1;
  background: transparent;
  color: #c37253;
  font-weight: bold;
  font-size: 14pt;
}

.expandLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.expandLinkPage:hover {
  cursor: pointer;
  text-decoration: underline;
}

.borderDinoz {
  border: 2px solid #d5965d;
  height: 152px;
  width: 572px;
}

.tbodyBuy {
  margin: 5px;
  height: 141px;
  width: 560px;
}

.picBox {
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
}

.dinoSheet {
  width: 150px;
  height: 130px;
}

.dinoSheet:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #8a4524;
}

.dinoSheetWrap {
  border-collapse: separate;
  border-spacing: 5px;
  border: 1px solid #efac71;
  width: 100px;
  height: 100px;
}

.buyButtonBox {
  margin-top: 4px;
}

.buyThisDinoz {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  padding-left: 5px;
  word-wrap: break-word;
  width: 65px !important;
  display: block;
  transform: translate(32px, -36px);
  font-size: 13px;
}

.buyThisDinoz:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.voteJAC {
  border: 2px solid #ba784b;
  margin-left: 23px;
  margin-top: 6px;
}

.fb_logo {
  max-width: 35px;
  border: 2px solid #ba784b;
  margin-left: 48px;
  margin-top: 12px;
}

.resThisDinoz {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  padding-left: 5px;
  word-wrap: break-word;
  display: block;
  transform: translate(32px, -34px);
  font-size: 13px;
}

.resThisDinoz:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.infoBox {
  width: 200px;
  transform: translate(-43px, -1px);
}

.fiche {
  color: #ffebd1;
  background-color: #efac71;
  padding-left: 10px;
  padding-bottom: 1px;
  font-weight: bold;
  font-size: 10pt;
  height: 22px;
  width: 180px;
  letter-spacing: 1px;
}

.competences {
  color: #ffebd1;
  background-color: #efac71;
  padding-bottom: 1px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 10pt;
  height: 22px;
  width: 248px;
  letter-spacing: 1px;
  transform: translate(-40px, -1px);
}

.ficheTable {
  font-weight: bold;
  padding-left: 9px;
  line-height: 10px;
}

.menuDinozFiche {
  padding-left: 20px;
  font-weight: normal;
}

.menuDinozFichePlayer {
  width: 117px;
  padding-left: 18px;
  font-weight: normal;
}

.classementInfos {
  padding-left: 25px;
  font-weight: normal;
}

.classementInfos:hover {
  cursor: default;
  font-weight: bold;
  font-style: italic;
}

.menuDinozFicheNiveau {
  padding-left: 20px;
  font-weight: bold;
}

.menuDinozFicheArena {
  white-space: nowrap;
  width: 200px;
  font-weight: normal;
  padding-left: 25px;
}

.levelColor {
  color: orange;
}

.levelColorWis {
  color: black;
  font-weight: normal;
}

.levelColorKab {
  color: purple;
}

.menuDinozPrice {
  padding-left: 20px;
  font-weight: bold;
  font-style: normal;
  font-size: 11pt;
  color: black;
}

.coin {
  padding-left: 4px;
}

.paddingRace {
  margin-right: 4px;
}

.capFont {
  font-size: 8pt;
  margin-right: 4px;
}

.elements {
  margin-left: 10px;
  margin-top: 5px;
  list-style: none;
  padding: 0px;
  clear: both;
  height: 45px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: separate;
  border-spacing: 5px;
  border-collapse: collapse;
  text-align: center;
}

.elementsLi {
  text-align: center;
  float: left;
  position: relative;
  margin-right: 6px;
  padding-top: 3px;
  width: 30px;
  background-color: #f7dcb7;
  list-style: none;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: separate;
  border-spacing: 5px;
  border-collapse: collapse;
  text-align: center;
}

.comp {
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: separate;
  border-spacing: 5px;
  border-collapse: collapse;
  text-align: center;
  transform: translate(-35px, 0px);
}

.ulComp {
  list-style: square;
  margin: 0px 0px 0px 20px;
  padding: 0px 0px 0px 0px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: separate;
  border-spacing: 5px;
  border-collapse: collapse;
}

.liComp {
  font-size: 9pt;
  list-style: square;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: separate;
  border-spacing: 5px;
  border-collapse: collapse;
}

.imgOffset {
  transform: translate(2px, 5px);
}

.minilineSeparation {
  transform: translate(8px, 0px);
  border: none;
  height: 1px;
  background-color: #efac71;
  width: 130px;
  margin: 0px;
}

.minilineHeal {
  border: none;
  height: 1px;
  background-color: #efac71;
  width: 130px;
  margin-bottom: 0px;
}

.dinozFromList {
  height: auto;
}

.hoverList:hover {
  height: auto;
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}
.hoverList--selected {
  background: #f7dcb7;
}

.hoverReturn {
  padding: 0px;
  border: 0px solid #ffebd1;
  background: transparent;
}

.hoverReturn:hover {
  cursor: pointer;
}

.pDinoz {
  line-height: 9pt;
  margin: 4px 0px 0px 7px;
  color: #c37253;
  font-weight: bold;
  list-style: none;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.pDinozExp {
  color: #c37253;
  margin: 0px;
  padding-left: 12px;
  font-style: italic;
  font-size: 9pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.pDinozExpDead {
  color: #efac71;
  margin: 0px;
  padding-left: 12px;
  font-style: italic;
  font-size: 9pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.pDinozLieu {
  color: #c37253;
  margin: 0px;
  margin-bottom: 5px;
  padding-left: 12px;
  font-style: italic;
  font-size: 9pt;
  font-weight: normal;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.pHeal {
  line-height: 9pt;
  margin: 4px 0px 0px 7px;
  color: darkorange;
  font-weight: bold;
  list-style: none;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.pDinozHeal {
  color: darkorange;
  margin: 0px 0px 5px;
  padding-left: 12px;
  font-style: italic;
  font-size: 9pt;
  font-weight: normal;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.tinyDetailPurple {
  transform: translate(-5px, 0px);
  float: left;
}

.tinyDetailGreen {
  transform: translate(-5px, 0px);
  float: left;
}

.pDinozDead {
  line-height: 9pt;
  margin: 4px 0px 0px 7px;
  color: #efac71;
  font-weight: bold;
  list-style: none;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
}

.pDinozLieuDead {
  color: #efac71;
  margin: 0px;
  margin-bottom: 5px;
  padding-left: 12px;
  font-style: italic;
  font-size: 9pt;
  font-weight: normal;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.actionDiv:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #8a4524;
}

.dino {
  border: 2px solid #f7dcb7;
  padding: 3px;
  width: 188px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  text-align: center;
}

.picCenter {
  max-height: 116px;
  background-color: #f7dcb7;
  width: 185px;
  padding-top: 7px;
  padding-bottom: 8px;
  margin: auto;
}

.picCenterFight {
  max-height: 116px;
  background-color: #f7dcb7;
  width: 185px;
  padding-top: 7px;
  padding-bottom: 8px;
  display: inline-grid;
}

.picCenterFightPlacer {
  background-color: #f7dcb7;
  border: 2px solid #d5965d;
  display: flex;
  flex-shrink: 0;
  width: 372px;
}

.charms {
  width: 37px;
  max-height: 116px;
  background-image: url("charm_bg.gif");
  background-position: center center;
  background-repeat: no-repeat;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
  text-indent: 5px;
}

.passiveTitle {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  transform: translate(-3px, -9px);
  text-align: center;
  text-decoration: underline #f7c2a4;
  color: #c37253;
  font-size: 8pt;
}

.def {
  margin-top: 5px;
  margin-bottom: 4px;
  margin-left: 2px;
  width: 184px;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.defFight {
  margin-top: 6px;
  margin-left: 6px;
  width: 176px;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.dinoTh {
  text-indent: 3px;
  background-color: #f7dcb7;
  font-size: 10pt;
  text-align: left;
  width: 30%;
  vertical-align: top;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.dinoTd {
  text-indent: 3px;
  background-color: #f7dcb7;
  font-size: 10pt;
  text-align: left;
  vertical-align: top;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.minispace {
  margin-right: 4px;
}

.bottomspace {
  margin-bottom: 60px;
}

.minibottom {
  border-bottom: 2px solid #ffebd1;
}

.minibottomVD {
  border-bottom: 2px solid #f7dcb7;
}

.percentages {
  margin-right: 4px;
  font-size: 9pt;
  padding-top: 1px;
  color: #98603a;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  text-align: center;
}

.bar {
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  text-align: center;
}

.skin {
  float: left;
  position: relative;
  width: 74px;
  height: 13px;
  margin-top: 2px;
  margin-right: 5px;
  line-height: 0pt;
  font-size: 0pt;
  background-image: url("bar.gif");
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.skinMedals {
  float: left;
  position: relative;
  width: 74px;
  height: 13px;
  margin-top: 2px;
  margin-right: 5px;
  background-image: url("bar.gif");
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.start {
  background-image: url("bar_start.gif");
  width: 6px;
  margin-left: 4px;
  margin-top: 3px;
  float: left;
  position: relative;
  height: 7px;
  line-height: 0pt;
  font-size: 0pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.fill {
  background-image: url("bar_fill.gif");
  margin-top: 3px;
  float: left;
  position: relative;
  height: 7px;
  line-height: 0pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.elementsDisplay {
  margin-left: 6px;
  padding-bottom: 47px;
}

.elementsLiFiche {
  padding-right: 1px;
  padding-left: 1px;
  margin-right: 4px;
  text-align: center;
  float: left;
  position: relative;
  padding-top: 4px;
  width: 30px;
  background-color: #f7dcb7;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  color: #98603a;
  font-weight: bold;
}

.compName {
  font-weight: bold;
  color: #98603a;
}

.compStars {
  padding-left: 20px;
  margin: -4px 0 4px;
}

.compHeight {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightFeu {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightTerre {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightEau {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightFoudre {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightAir {
  padding-left: 2px;
  width: 173px;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 6px;
}

.compHeightFeu:hover {
  background-image: url(element0.gif);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f7dcb7;
}

.compHeightTerre:hover {
  background-image: url(element1.gif);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f7dcb7;
}

.compHeightEau:hover {
  background-image: url(element2.gif);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f7dcb7;
}

.compHeightFoudre:hover {
  background-image: url(element3.gif);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f7dcb7;
}

.compHeightAir:hover {
  background-image: url(element4.gif);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f7dcb7;
}

.ulCompDinoz {
  margin: 3px 0px 0px 6px;
  padding: 0px 0px 0px 0px;
  text-align: left;
}

.imgLieuxFight {
  border: 2px solid #d5965d;
  vertical-align: top;
  margin-left: 47px;
  width: 150px;
  height: 150px;
}

.imgLieuxFight:hover {
  color: #8a4524;
  cursor: pointer;
  border: 2px solid #8a4524;
}

.textLocation {
  border-collapse: collapse;
  vertical-align: top;
}

.actionTitle {
  padding-top: 6px;
  text-align: left;
  color: #e4aa76;
  font-weight: bold;
  font-size: 8pt;
  text-transform: uppercase;
  margin-bottom: -4px;
}

.actionTitleDeplacer {
  text-align: left;
  color: #e4aa76;
  font-weight: bold;
  font-size: 8pt;
  text-transform: uppercase;
  margin-bottom: -4px;
}

.flex {
  display: flex;
  padding-bottom: 5px;
}

.map {
  pointer-events: none;
  border: 1px solid black;
  font-size: 0pt;
  line-height: 0pt;
}

.transformCharm {
  transform: translate(0px, -15px);
}

.actionList {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 0.5rem;
  color: #c37253;
  font-weight: bold;
}

.textAction {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 13px;
  line-height: 1.1;
}

.inventory {
  display: inline;
  text-align: justify;
  vertical-align: top;
}

.actionList:hover {
  color: #8a4524;
  cursor: pointer;
}

.textAction:hover {
  color: #8a4524;
  cursor: pointer;
}

.attack {
  color: #ffbb72;
  height: 21px;
  width: 190px;
  margin-top: 7px;
  padding-top: 3px;
  background-image: url(button_attack.gif);
  text-transform: uppercase;
  cursor: pointer;
  line-height: 16px;
}

.attackHermit {
  color: #ffbb72;
  height: 21px;
  width: 190px;
  margin-top: 22px;
  padding-top: 3px;
  background-image: url(button_attack.gif);
  text-transform: uppercase;
  cursor: pointer;
  line-height: 16px;
}

.lancercombat {
  margin-top: 1px;
  text-align: center;
  font-weight: bold;
}

.lancercombat:hover {
  font-weight: bold;
  color: white;
}

.borderDinozFight {
  border: 2px solid #d5965d;
  height: 112px;
  width: 572px;
  margin-bottom: 9px;
  line-height: 16px;
}

.borderDinozFightHermit {
  border: 2px solid #efac71;
  height: 112px;
  width: 581px;
  margin-bottom: 9px;
  line-height: 16px;
}

.borderDinozFightInProfil {
  border: 2px solid #d5965d;
  height: 112px;
  width: 310px;
  margin-bottom: 9px;
  line-height: 16px;
}

.borderDinozFightInPantheon {
  border: 2px solid #efac71;
  height: 112px;
  width: 310px;
  margin-bottom: 12px;
  line-height: 16px;
}

.padNull {
  padding: 0px;
  margin: 0px;
}

.ficheTableFight {
  padding-left: 9px;
  line-height: 11px;
}

.fichePlayerCard {
  padding-left: 7px;
  line-height: 12px;
}

.infoBoxRight {
  transform: translate(-40px, -1px);
}

.picBoxFight {
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
}

.tbodyFight {
  margin: 5px;
  height: 102px;
  width: 560px;
}

.tbodyFightTelescope {
  margin: 5px;
  height: 102px;
  width: 300px;
}

.tbodyBazar {
  margin: 5px;
  height: 102px;
  width: 560px;
}

.sizeTrFight {
  display: block;
  width: 100px;
  height: 100px;
}

.ficheInfosComp {
  color: #ffebd1;
  background-color: #efac71;
  padding-left: 10px;
  padding-bottom: 1px;
  font-weight: bold;
  font-size: 10pt;
  height: 22px;
  width: 245px;
  letter-spacing: 1px;
}

.dinoSheetWrapFight {
  transform: translate(0px, -1px);
  border-collapse: separate;
  border-spacing: 5px;
  border: 1px solid #efac71;
  width: 100px;
  height: 100px;
}

.majorElement {
  font-weight: normal;
}

.HPOnManny {
  transform: translate(10px, 10px);
}

.majorElementML {
  transform: translate(0px, -3px);
  font-weight: normal;
  padding-right: 2px;
  text-align: center;
  font-size: 9pt;
}

.infoBoxFight {
  transform: translate(-44px, -1px);
}

.playerLink {
  padding-left: 20px;
  font-weight: normal;
}

.paddingSpace {
  padding-left: 16px;
  padding-right: 8px;
}

.fightDescription {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
}

.textbox {
  width: 570px;
}

.continueAfterFight {
  margin-top: 8px;
  padding-left: 320px;
  padding-bottom: 13px;
  border: 0px solid #ffebd1;
  background: transparent;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  font-size: 11pt;
  text-decoration: underline;
}

.continueAfterFight:hover {
  background-color: rgba(236, 221, 203, 0.96);
  cursor: pointer;
}

.imgLieuxFightAfter {
  border: 2px solid #d5965d;
  width: 100px;
  margin-left: 484px;
}

.textContinueFight {
  padding-right: 11px;
  margin-bottom: 4px;
}

.battle {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.miniElemsDiv {
  margin-left: 11px;
}

.result {
  transform: translate(-12px, 0px);
  font-size: 14pt;
  font-weight: bold;
  color: #98603a;
  margin-bottom: 20px;
  border-bottom: 2px solid #efac71;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.layout {
  width: 100%;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.columnWinner {
  max-height: 400px;
  border: 1px solid #efac71;
  transform: translate(-12px, 0px);
}

.layoutRewards {
  line-height: 17px;
  vertical-align: top;
  word-break: break-word;
  width: 484px;
  border-bottom: 2px solid #f4d1a2;
  padding-bottom: 9px;
  padding-top: 9px;
}

.returnFiche {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
}

.returnFiche:hover {
  cursor: pointer;
  color: #8a4524;
}

.rewardsOffset {
  transform: translate(0px, -8px);
}

.textSummary {
  vertical-align: top;
  padding-left: 10px;
  color: #98603a;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.colorBlack {
  vertical-align: top;
  padding-left: 10px;
  color: black;
}

.masterName {
  color: black;
  font-size: 13px;
}

.colorBlackPO {
  vertical-align: top;
  font-size: 11pt;
  font-weight: bold;
  color: black;
}

.colorBlackPOMerchant {
  margin-right: 200px;
  vertical-align: center;
  font-size: 11pt;
  font-weight: bold;
  color: black;
}

.colorBlackNP {
  vertical-align: top;
  color: black;
}

.colorBeige {
  vertical-align: top;
  padding-left: 10px;
  color: #98603a;
}

.imgSummary {
  float: left;
}

.overflowHidden {
  overflow: hidden;
}

.clickableName {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
}

.clickableName:hover {
  cursor: pointer;
  color: #8a4524;
}

.listBlack {
  list-style: circle;
  text-align: left;
  padding-left: 10px;
  color: black;
}

.marginList {
  margin: 5px;
}

.digResult {
  max-height: 38px !important;
  color: black;
  transform: translate(-13px, 0px);
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 538px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
}

.digImageTranslate {
  transform: translate(-47px, 3px);
}

.digText {
  transform: translate(-8px, -32px);
  display: flex;
}

.errorTourney {
  color: red !important;
  background-color: #ffe4c1 !important;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 538px;
  height: 32px;
  line-height: 18px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt !important;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_error.gif);
}

.errorResultFuzPrix {
  color: red;
  transform: translate(-0px, 0px);
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 521px;
  line-height: 18px;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 11px;
  margin-bottom: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_error.gif);
}

.errorResult {
  color: red;
  transform: translate(-13px, 0px);
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 538px;
  line-height: 18px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_error.gif);
}

.successResult {
  line-height: 18px;
  color: black;
  transform: translate(-13px, 0px);
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 538px;
  padding-left: 50px;
  padding-right: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
  margin-bottom: 10px;
}

.fusionMessage {
  color: black;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 524px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
}

.merchantMessage {
  color: black;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 521px;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
}

.merchantError {
  color: red;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 524px;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_error.gif);
}

.newsPopup {
  transform: translate(-13px, 0px);
  color: black;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 548px;
  padding-left: 50px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
}

.agilityResult {
  transform: translate(-13px, 0px);
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 538px;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 18px;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_defense.gif);
}

.winLevelText {
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  width: 570px;
}

.evoTable {
  transform: translate(-9px, 0px);
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-bottom: 10px;
  margin-top: 10px;
  border-collapse: collapse;
}

.evoTableHeaders {
  line-height: 18px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  background-color: #efac71;
  color: #ffebd1;
  font-weight: bold;
}

.evoInfo {
  width: 35px;
  text-align: center;
}
.evoName {
  width: 160px;
  text-align: center;
}
.evoElem {
  width: 100px;
  text-align: center;
}
.evoNiv {
  width: 180px;
  text-align: center;
}
.evoEvo {
  width: 120px;
  text-align: center;
}

.evoInfoData {
  width: 35px;
  text-align: center;
}
.evoNameData {
  width: 160px;
  text-align: center;
  font-weight: bold;
}
.evoElemData {
  width: 100px;
  text-align: center;
}
.evoNivData {
  width: 180px;
  text-align: center;
  color: #d28e51;
}
.evoEvoData {
  width: 120px;
  text-align: center;
  color: blue;
  font-weight: bold;
}

.evoEvoDataLearn {
  width: 120px;
  text-align: center;
  color: green;
  font-weight: bold;
}

.evoEvoData:hover {
  text-decoration: underline;
  cursor: pointer;
}

.evoEvoDataLearn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.evoEvoDataMaximum {
  width: 120px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.evoEntry {
  border-bottom: 1px solid #efac71;
}

.evoImg {
  transform: translate(0px, 2px);
}

.textBoutique {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 18px;
}

.textBoutiqueElement {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 16px;
  margin: 0px;
}

.textBoutiqueSommaire {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 5px;
  font-style: italic;
  font-weight: bold;
}

.textBoutiqueTotal {
  padding-left: 110px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  line-height: 5px;
  font-size: 11pt;
  font-weight: bold;
  color: black;
}

.componentBoutique {
  width: 571px;
  padding-bottom: 11px;
}

.componentFusions {
  text-align: center;
  padding-bottom: 11px;
}

.WIP {
  margin-bottom: 10px;
}

.shopItems {
  transform: translate(-10px, 0px);
  margin-top: 10px;
  border-bottom: 3px solid #efac71;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.shopItemsBoutique {
  margin-top: 10px;
  border-bottom: 3px solid #efac71;
  width: 103%;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.shopItemsMerchant {
  margin-top: 10px;
  border-bottom: 3px solid #efac71;
  width: 98%;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.stock {
  width: 140px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-bottom: 3px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.tableColor {
  background-color: #ffe4c1;
}

.miniElems {
}

.nameTitle {
  width: 140px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  border-bottom: 3px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.empty {
  width: 0px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-bottom: 3px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.price {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.priceIrma {
  vertical-align: middle;
  text-align: center;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.totalIrma {
  padding-left: 7px;
  margin-bottom: 7px;
}

.priceHeader {
  vertical-align: middle;
  text-align: center;
  border-bottom: 3px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.quantityHeader {
  text-align: right;
  vertical-align: middle;
  border-bottom: 3px solid #efac71;
  color: #efac71;
  font-size: 12pt;
  border-collapse: collapse;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.icon {
  width: 45px;
  padding-left: 5px;
  padding-top: 3px;
  border-bottom: 1px solid #efac71;
  height: 45px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: center;
  border-collapse: collapse;
}

.iconIrma {
  width: 45px;
  padding-left: 5px;
  padding-top: 3px;
  height: 45px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: center;
  border-collapse: collapse;
}

.name {
  vertical-align: center;
  border-bottom: 1px solid #efac71;
  height: 45px;
  width: 210px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.buy {
  border-bottom: 1px solid #efac71;
  height: 45px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
  border-collapse: collapse;
}

.possession {
  font-style: italic;
  color: #ba784b;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.titreObjet {
  width: 190px;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
  margin: 0px;
}

.titreObjetHermit {
  width: 215px;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
  margin: 0px;
}

.espaceNom {
  padding-right: 5px;
}

.choiceInventory {
  background-color: #ffe4c1;
}

.choiceInventoryWithSelect {
  margin-right: 15px;
  background-color: #ffe4c1;
}

.choiceFilterBazar {
  margin-left: 420px;
  margin-bottom: 10px;
  width: 165px;
  background-color: var(--backgroundcolor);
}

.choiceFilterPantheon {
  transform: translate(0px, -70px);
  margin-bottom: 10px;
  width: 173px;
  background-color: var(--backgroundcolor);
}

.count {
  vertical-align: middle;
  text-align: right;
  border-bottom: 1px solid #efac71;
}

.countIrmaShop {
  padding-left: 7px;
  padding-right: 70px;
  transform: translate(0px, -8px);
}

.buySommaire:hover {
  background-color: #f4d1a2;
  cursor: pointer;
}

.buyPic {
  text-align: right;
  vertical-align: middle;
  padding-top: 4px;
  padding-left: 418px;
}

.numberField {
  width: 55px;
  background-color: var(--backgroundcolor);
}

.numberFieldInv {
  width: 55px;
  background-color: #ffe4c1;
}

.numberFieldBazar {
  width: 63px;
  background-color: #ffe4c1;
}

.numberFieldBazarBis {
  margin-right: 15px;
  margin-left: 5px;
  transform: translate(0px, -10px);
  width: 63px;
  background-color: #ffe4c1;
}

.textFusions {
  white-space: pre-wrap;
  width: 475px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.textNpcTalk {
  white-space: pre-wrap;
  width: 455px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.blockBuySingleItem {
  max-width: 550px;
  padding-left: 20px;
}

.textTalk {
  margin-top: 2px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-style: italic;
}

.text {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.textPrize {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: center;
}

.textPrizeCC {
  pointer-events: none;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: center;
}

.textElementDotted {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-bottom: 5px;
}

.textIrma {
  width: 414px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.centerConversationTextWithLineBreaks {
  width: 575px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-style: italic;
  white-space: pre-line;
}

.textIrmaNarrow {
  width: 371px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.textCerbere {
  width: 460px;
  text-align: justify;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 15px;
}

.textIrma2 {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-top: 6px;
  width: 580px;
}

.textDots {
  width: 558px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 25px;
  margin-top: 8px;
}

.textNarrow {
  width: 363px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.textWarningPitie {
  width: 580px;
  text-align: left;
  font-weight: bolder;
  color: #7952c8;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-top: 2px;
}

.textSpecial1 {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-top: 6px;
  width: 580px;
  font-style: italic;
}

.textSpecialFusions {
  white-space: pre-wrap;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-top: 6px;
  width: 580px;
}

.floatLeft {
  float: left;
  margin-right: 10px;
}

.headerFusion {
  width: 585px;
  color: #efac71;
  margin-top: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #efac71;
  font-size: 14pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.miniHeaders {
  transform: translate(-12px, 0px);
  color: #efac71;
  margin-top: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #efac71;
  font-size: 14pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.miniHeaders2 {
  width: 585px;
  color: #efac71;
  margin-top: 5px;
  margin-bottom: 3px;
  border-bottom: 1px solid #efac71;
  font-size: 14pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.collection {
  width: 580px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;
}

.center {
  text-align: center;
  margin: auto;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.rewardBox {
  margin: auto;
  width: 240px;
  background-image: url(reward_bg.gif);
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.headercol {
  border-collapse: collapse;
  text-align: center;
  background-image: url(reward_header.gif);
  height: 49px;
}

.footercol {
  height: 232px;
  text-align: center;
  background-image: url(reward_footer.gif);
  background-position: bottom left;
  background-repeat: no-repeat;
  border-collapse: collapse;
}

.footercolepic {
  text-align: center;
  background-image: url(reward_footer.gif);
  background-position: bottom left;
  background-repeat: no-repeat;
  border-collapse: collapse;
}

.rewardCount {
  transform: translate(0px, 10px);
  font-weight: bold;
  color: #ffbb72;
  font-size: 10pt;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.rewards {
  table-layout: fixed;
  width: 224px;
  height: 224px;
  margin: auto;
  font-weight: bold;
  text-align: center;
  border-collapse: collapse;
}

.contact {
  text-align: justify;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  max-width: 580px !important;
}

.contactImg {
  margin-top: 20px;
  max-width: 580px !important;
}

.rewardsEpic {
  margin: auto;
  border-collapse: collapse;
}

.headerEpic {
  padding-top: 50px;
  font-size: 13pt;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.headerCollection {
  font-size: 13pt;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.noPad {
  padding: 0px;
  margin: 0px;
  width: 32px;
  height: 32px;
}

.inline {
  display: flex;
}

.epicDisplay {
  display: flex;
}

.pt-40 {
  padding-top: 40px;
}

.linkDiscord {
  margin-top: 10px;
  margin-bottom: 15px;
}

.linkDiscord:hover {
  background-color: #f4d1a2;
  cursor: pointer;
}

.classementHeader {
  text-align: center;
  padding-right: 14px;
  margin-bottom: 7px;
}

.classementText {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.classementCategory {
  border: 2px solid #ffebd1;
  background: transparent;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  font-size: 12pt;
  border-collapse: collapse;
}

.classementCategory:hover {
  text-decoration: underline;
  cursor: pointer;
}

.containerClassements {
  max-width: 560px;
}

.rankingsTable {
  table-layout: fixed;
  margin-top: 10px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  width: 585px;
}

.trRankings {
  background-color: #f4d1a2;
  text-align: left;
}

.position {
  width: 40px;
  padding: 0px 0px 0px 5px;
}

.active {
  width: 30px;
}

.pseudo {
  width: 140px;
  padding: 0px 0px 0px 10px;
}

.nbdi {
  text-align: center;
}

.nbPoints {
  text-align: right;
  width: 120px;
  padding: 0px 5px 0px 0px;
}

.selectedEntry {
  background-color: #f7dcb7;
}

.selectedEntry:hover {
  background-color: var(--backgroundcolor);
  cursor: pointer;
  font-weight: bold;
  color: #c37253;
  font-size: 11pt;
}

.returnArrows {
  border: 2px solid #ffebd1;
  background: transparent;
}

.returnArrowLeft {
  transform: translate(0px, 10px);
}

.returnArrowLeft:hover {
  cursor: pointer;
}

.returnArrowRight {
  transform: translate(0px, 10px);
  padding-left: 20px;
}

.returnArrowRight:hover {
  cursor: pointer;
}

.skipArrowRight {
  transform: translate(0px, 10px);
}

.skipArrowRight:hover {
  cursor: pointer;
}

.searchMyRank {
  border: 2px solid #ffebd1;
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  color: #c37253;
  font-weight: bold;
}

.searchMyRank:hover {
  cursor: pointer;
  text-decoration: underline;
}

.rankingsBottom {
  width: 585px;
  text-align: center;
}

.spanPagination {
  padding-left: 15px;
}

.profil {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.shiftCollection {
  z-index: 3;
  transform: translate(0px, -273px);
  margin-left: 335px;
  position: relative;
}

.translateUp {
  padding-top: 6px;
  transform: translate(0px, -268px);
}

.shiftInfos {
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 21px;
  height: 280px;
}

.paddingListDinoz {
  margin-left: 129px;
}

.reactBoyRogner {
  margin-top: 50px;
  width: 170px;
}

.historyEvent {
  text-align: justify;
  vertical-align: top;
  color: black;
  background-color: #ffe4c1;
  /* width: 575px; */
  /* height: 37px; */
  margin-bottom: 9px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 15px;
  border-collapse: collapse;
  border: 2px solid #efac71;
}

.history {
  /* transform: translate(40px, -35px); */
  /* width: 520px; */
}

.historyEventUnseen {
  text-align: justify;
  vertical-align: top;
  color: black;
  background-color: #ffd8b8;
  /* width: 575px; */
  /* height: 37px; */
  margin-bottom: 9px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 15px;
  border-collapse: collapse;
  border: 2px solid #efac71;
}

.headerHistory {
  width: 575px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.compteHeader {
  width: 568px;
  margin-bottom: 15px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.tableMyAcc {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.buttonImport {
  margin-bottom: 10px;
  margin-right: 55px;
  width: 150px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonImport:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonGeneral {
  margin-left: 70px;
  width: 100px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonGeneral:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonSingleBuy {
  margin-left: 50px;
  width: 100px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonSingleBuy:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonControl {
  margin-left: 230px;
  margin-bottom: 20px;
  width: 100px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonControl:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.seeProfile {
  width: 150px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.seeProfile:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.imgIrma {
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
}

.imgPnj {
  width: 150px;
  height: 150px;
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
}

.imgPnjNotResizable {
  width: 100px;
  height: 100px;
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
  max-width: none;
}

.imgRaymond {
  width: 100px;
  height: 100px;
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
}

.textCatapulte {
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-size: 10pt;
  font-weight: bold;
}

.textCatapulte:hover {
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #8a4524;
  font-size: 10pt;
  font-weight: bold;
}

.colorBlackPOCatapulte {
  padding-left: 5px;
  vertical-align: center;
  font-size: 11pt;
  font-weight: bold;
  color: black;
}

.radioChoices {
  padding-top: 12px;
  padding-left: 12px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}
.panel {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  width: 596px;
  transform: translate(-6px, 0px);
  border-collapse: separate;
  border-spacing: 6px;
}

.panel th {
  font-size: 12pt;
  background-color: #efac71;
  color: #ffebd1;
}

.panel td {
  border: 2px solid #f4d1a2;
  border-spacing: 0px;
}

.panel tbody td.rewards {
  height: 110px;
  width: 100vw;
}

.panel p {
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.panel .target {
  color: #98603a;
}
.panel .title {
  font-weight: bold;
}

.panel .bar {
  margin-left: 54px;
}

.panel .pic {
  margin-top: 6px;
  margin-bottom: 2px;
}

.btnSpacer {
  display: inline;
  margin-right: 90px;
}

.medals {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.bannerTor {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

.appChooser {
  width: 25px;
  text-align: center;
  margin: 10px 5px 5px;
}

.creationContainer {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.imgFixedSize {
  height: 100px;
  width: 100px;
}

.alignedOptions {
  display: flex;
  padding: 0.4rem;
}

.alignedOptions input[type="radio"] {
  margin-top: 1.5px;
}

.dialogNPC {
  height: 90px;
  padding: 6px 10px 6px 0px;
}

.italic {
  font-style: italic;
}

.fusionTable {
  width: 585px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.dinoSheetWrapFusion {
  border: 1px solid #efac71;
  width: 100px;
  height: 100px;
}

.textNpc {
  width: 475px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 10px;
  margin: 0px;
}

.specialMenuOption {
  padding: 4px 0px 6px 0px;
  opacity: 0.8;
}
.specialMenuOption .elementOfMenu {
  padding-left: 0px;
}

.inline-block {
  display: inline-block;
}

.plusEqualsSigns {
  color: #efac71;
  font-size: 35pt;
}

.buttonFusion {
  width: 225px;
  height: 40px;
  margin: 23px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonFusion:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonFusionContinue {
  margin-right: 13px;
  margin-top: 15px;
  width: 254px;
  height: 23px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonFusionContinue:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.overlay-custom {
  color: crimson;
}

/* Overriding default styles of react-confim library */

.react-confirm-alert-overlay {
  background-color: rgba(247, 194, 164, 0.6) !important;
}

.react-confirm-alert-overlay .react-confirm-alert-body {
  text-align: center !important;
  color: #f7c2a4 !important;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-weight: bold;
}

.react-confirm-alert-overlay .react-confirm-alert-button-group {
  display: block !important;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.react-confirm-alert-button-group button {
  background-color: #c37253 !important;
}
.react-confirm-alert-button-group button:first-of-type {
  opacity: 1;
}

/* End react-confirm overrigind */

.textFuzSummary {
  width: 582px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.fusionResult {
  padding-top: 90px;
  padding-right: 16px;
  margin-left: 6px;
  text-align: center;
  height: 200px;
  background-image: url("background_fusion.png");
  background-repeat: no-repeat;
  margin-top: 12px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.chaudron {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 290px;
  box-sizing: border-box;
  padding: 1.25rem 1rem;
  text-align: center;
  background: 50% 50% url("background_chaudron.gif") no-repeat;
}

.buttonChaudron {
  margin-top: auto;
}

.resultCraftChaudron {
  text-align: center;
}

.fuzSummaryLevel {
  padding: 0px 0px 0px 0px;
}

.fuzSummaryElement {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 18px;
  margin: 0px 30px 0px 15px;
}

.tableRewardFuz {
  width: 253px;
  margin-left: 168px;
  margin-top: 13px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border: 2px solid #efac71;
  background-color: var(--backgroundcolor);
}

.tableRewardHermit {
  margin-left: 150px;
  margin-top: 8px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border: 2px solid #efac71;
  background-color: var(--backgroundcolor);
}

.marginRewardFuz {
  padding-right: 10px;
  text-align: center;
}

.marginRewardHermit {
  padding-right: 5px;
  text-align: center;
}

#messageList {
  border-bottom: 2px solid #efac71;
  background-color: #ffe4c1;
  border-collapse: separate;
  border-spacing: 3px;
  text-align: left;
}

.messageListLayout {
  display: grid;
  min-width: 100%;
  grid-template-columns:
    minmax(80px, 2fr)
    minmax(80px, 5fr)
    minmax(80px, 2fr)
    minmax(80px, 1fr);
}

#messageList th {
  background-color: #efac71;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

#messageActions {
  display: inline-flex;
  color: #c37253;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 1rem;
  float: right;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0px;
}

.messageListLayout td {
  padding-left: 5px;
  padding-right: 5px;
}

.messageFrom {
  font-weight: bold;
  cursor: pointer;
  margin: auto;
  margin-left: 0px;
}

.messagePreview {
  color: #c37253;
  cursor: pointer;
  margin: auto;
  margin-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.messageDateTime {
  font-size: 11px;
  margin: auto;
  margin: auto;
  margin-left: 0px;
}

.messageCheckbox {
  margin: auto;
  position: relative;
}

.messageCheckbox img {
  width: 12px;
  position: absolute;
  top: 4.5px;
  right: -12px;
  cursor: pointer;
  filter: invert(88%) sepia(90%) saturate(3460%) hue-rotate(303deg)
    brightness(91%) contrast(106%);
}

#goBackToMsgList {
  cursor: pointer;
}

.unseenMsg {
  background-color: #f8ca8d;
  font-weight: bold;
}

#createNewMsg {
  flex-flow: row nowrap;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
}

#createNewMsg img {
  padding-left: 3px;
  padding-right: 6px;
}

#messagesHeader {
  display: grid;
  grid-template-columns: 5fr 9fr;
}

#newMsgBody {
  width: 99%;
  resize: none;
}

#newMsgHeader {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 70%;
}

#newMsgHeader span {
  margin: auto;
  margin-bottom: 0px;
  cursor: pointer;
}

#blackListAddition {
  display: grid;
  grid-template-columns: 6fr 1fr;
  width: 60%;
  margin: auto;
  margin-bottom: 1rem;
}

#blackListAddition span {
  margin: auto;
  display: flex;
  cursor: pointer;
}

.blackListPlayer img {
  cursor: pointer;
  float: right;
}

.blackListPlayer {
  display: grid;
  grid-template-columns: 8fr 1fr;
  width: 50%;
  margin: auto;
  border: 2px solid #c37253;
  margin-top: 0.5rem;
}

.blackListPlayer span {
  margin: auto;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
}

.foreignMessage {
  justify-content: start;
}

.foreignMessage > span {
  display: inline-block;
  padding: 0.45rem;

  background-color: #f8ca8d;
  padding-right: 1rem !important;
  border-left-style: double;
  border-top-right-radius: 5%;
  border-bottom-right-radius: 5%;
}
.ownMessage {
  justify-content: right;
}

.ownMessage > span {
  display: inline-block;
  background-color: #d8bc98c4;
  padding-left: 1rem !important;
  padding: 0.45rem;
  border-right-style: double;
  border-top-left-radius: 5%;
  border-bottom-left-radius: 5%;
}

.messageDetail {
  display: grid;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  white-space: pre-line;
}

#messageThread hr {
  height: 0px;
  visibility: hidden;
  border: none;
}

#messageThread {
  margin-top: 10px;
  height: 60vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#messageThread::-webkit-scrollbar {
  display: none;
}

#stickyThreadOverlay {
  position: absolute;
  display: flex;
  background-color: var(--backgroundcolor);
  height: 90px;
  width: 100%;
  mask: linear-gradient(#fff, transparent);
}

#threadView {
  position: relative;
}

#threadPlayerName {
  width: 65%;
  margin: auto;
  text-align: center;
  border-top: 2px solid #c3735394;
  padding: 0.3rem;
  border-bottom: 2px solid #c3735394;
  color: #c37253;
  font-weight: bold;
  font-size: 17px;
  font-style: italic;
}

#threadNewMsg {
  position: relative;
  margin: 1rem 0.4rem 1rem 0.3rem;
  display: flex;
}

#threadNewMsg span {
  position: absolute;
  top: 21px;
  right: 20px;
  cursor: pointer;
}

#threadNewMsgBody {
  padding: 7px;
  padding-right: 12%;
  width: 88%;
  resize: none;
}

.textWarning {
  font-style: italic;
  color: #c37253;
}

.messageSendingSpinner {
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-flex;
}

.messageSendingSpinner p {
  margin: auto;
  margin-left: 10px;
}

.unSeenMsg > span {
  border-top: 2.5px solid #c37253;
  font-weight: bold;
  border-bottom: 2.5px solid #c37253;
  border-right: 2.5px solid #c37253;
}

.threadMsgDate {
  color: #c3735394;
  font-style: italic;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 3px;
}

.ownMessage .threadMsgDate {
  text-align: right;
  margin-right: 3px;
}

#threadShowAllMsgs {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  margin-top: 10px;
}

#previewPagination {
  display: inline-flex;
  width: 100%;
  margin-top: 2rem;
}
#previewPagination img {
  margin: auto;
  cursor: pointer;
}

#threadMsgsWrapper {
  min-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2rem;
}

.centerSpan {
  transform: translate(10px, 10px);
}

.bodyMsgWrapper {
  position: relative;
  width: 100%;
}

.bodyMsgWrapper span:not(.centerSpan) {
  position: absolute;
  top: -16px;
  right: 1px;
  color: #c37253;
  margin-right: 3px;
  font-style: italic;
}

#threadMsgBodyCounter {
  top: 6px !important;
  right: 0px !important;
}

.msgButtons {
  display: inline-flex;
  color: #c37253;
  cursor: pointer;
  font-weight: bold;
  float: right;
  justify-content: flex-end;
  border: 1.5px solid #c37253;
  padding: 4px;
  margin: auto 0px auto 5px;
  color: #c37253;
  background-color: var(--backgroundcolor);
}

.msgButtons:hover {
  color: #fcead1;
  border: 1.5px solid #8a4524;
  padding: 4px;
  margin-left: 5px;
  background-color: #efac71;
}

.flecheGauche {
  transform: translate(-13px, 0px);
  height: 23px;
  width: 37px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 20pt;
  line-height: 20px;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.flecheGauche:hover {
  cursor: pointer;
  background-color: #f7dcb7;
  color: #efac71;
}

.flecheDroite {
  text-align: right;
  transform: translate(-13px, 0px);
  height: 23px;
  width: 37px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
  font-size: 20pt;
  line-height: 20px;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.flecheDroite:hover {
  cursor: pointer;
  background-color: #f7dcb7;
  color: #efac71;
}

.pageCategoryHeaderDinozFiche {
  flex-grow: 1;
  transform: translate(-13px, 0px);
  height: 23px;
  color: #ffebd1;
  background-color: #efac71;
  margin-top: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
}

.ligneDinozFleche {
  flex-shrink: 0;
  display: flex;
}

.buttonGiveaway {
  margin-left: 140px;
  margin-bottom: 25px;
  margin-top: 25px;
  width: 300px;
  height: 45px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonGiveaway:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonRewardsArena {
  margin-left: 212px;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 150px;
  height: 30px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonRewardsArena:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonSacrifice {
  line-height: 10px;
  margin-bottom: 25px;
  padding: 5px;
  height: 46px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonSacrifice:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonGiftPrize {
  margin-left: 160px;
  line-height: 10px;
  margin-bottom: 10px;
  height: 42px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonGiftPrize:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonGiftDinotown {
  margin-left: 15px;
  line-height: 10px;
  margin-bottom: 10px;
  height: 42px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonGiftDinotown:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.centerBtn {
  display: flex;
  justify-content: center;
}

.overrideCenterButton {
  margin-left: 220px;
}

.verticalAlignCenter {
  line-height: 30px;
  vertical-align: top;
  padding: 10px;
}

.autelAnim {
  outline: 3px solid #7952c8;
  outline-offset: -2px;
  margin-left: 106px;
}

.textPercentage {
  text-align: center;
  color: #7952c8;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.merchantBuyPanel {
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  width: 20%;
  margin-top: 15px;
}

.merchantBuyPanel span {
  margin: auto;
}

.merchantBuyPanelAll {
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  width: 500px;
  margin-top: 15px;
}

.merchantBuyPanelAll span {
  margin: auto;
}

.merchantBuyPanel img {
  cursor: pointer;
}

.merchantBuyPanel:hover {
  background-color: #f4d1a2;
  cursor: pointer;
}

.merchantBuyPanelAll:hover {
  background-color: #f4d1a2;
  cursor: pointer;
}

.ingredientPrice {
  padding: 5px;
  font-style: italic;
  float: left;
  color: #c37253;
  font-size: 12px;
  opacity: 0.8;
}

#ingredientsAmount {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-weight: bold;
}

#adminButton {
  display: grid;
  font-size: 10pt;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

#adminButton a {
  margin-top: 28px;
  margin-left: 28px;
  color: #c37253;
}

.logEventDetail {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}
.logEventDetail span {
  padding: 5px;
}

#loggingList {
  max-height: 45vh;
  overflow: auto;
}

.logDateTime {
  color: #c37253;
  font-style: italic;
}

.adminPlayerRow {
  display: inline-grid;
  width: 85%;
  text-align: center;
  border: 0.8px solid #c37253;
  border-radius: 10px;
  background-color: #efac7142;
  margin-bottom: 5px;
}
.adminPlayerName {
  cursor: pointer;
  text-decoration: underline;
  color: #c37253;
  padding: 2px;
}

#adminPlayersList {
  text-align: center;
  max-height: 80vh;
  overflow: auto;
}

.search {
  width: 85%;
  position: relative;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
}

.searchTerm {
  width: 100%;
  border: 3px solid #c37253;
  padding: 5px;
  height: 20px;
  border-radius: 5px 5px 5px 5px;
  outline: none;
  color: #c37253;
}

.searchTerm:focus {
  color: #c37253;
}

.adminMajOption {
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #efac71;
  border: 2px solid #c37253;
  border-radius: 5px;
  color: black;
}

.adminStickedInput {
  padding: 5px;
  transform: translate(-5px, 0);
  max-width: 72px;
  border: 2px solid #c37253;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#adminResponseMessage {
  padding: 10px;
  border: 2.5px solid orangered;
  margin-bottom: 10px;
  margin-top: 25px;
  border-radius: 3.5px;
  text-align: center;
  position: relative;
  background-color: orange;
  font-style: italic;
}

#adminOverText {
  position: absolute;
  left: 10px;
  top: -18px;
  font-weight: bold;
}

#majPanel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;
}

#adminPlayerDetails {
  margin: 25px 10px 25px 10px;
  display: grid;
  grid-template-rows: 1fr 6fr 1fr;
  grid-gap: 10px;
  background-color: bisque;
  border-top: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  padding: 5px;
  grid-gap: 10px;
}

#adminPlayerDetails div:first-of-type {
  display: inline-flex;
  justify-content: space-evenly;
}

#adminPlayerDetails div:first-child span {
  text-decoration: underline;
  text-decoration-color: #efac71;
  text-decoration-thickness: 2px;
}

#adminPlayerInventory {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  margin: auto;
  width: 90%;
  grid-gap: 5px;
  font-style: italic;
  color: #c37253;
  border-left: 1px solid #c37253;
  border-right: 1px solid #c37253;
}

.arenaSummary {
  text-align: left;
  width: 585px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.tableArenaOne {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  margin-top: 10px;
  width: 585px;
  font-weight: bold;
  color: #efac71;
  font-size: 12pt;
}

.tableArenaOne tr td {
  padding-right: 35px;
}

.tableArenaOne a {
  margin-left: 2px;
  text-align: center;
}

.rewardsArena {
  margin: 0px;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  width: 585px;
  font-weight: bold;
  color: #efac71;
  font-size: 12pt;
}

.buttonChoice {
  margin-top: 20px;
  margin-left: 92px;
  width: 150px;
  height: 30px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonChoice:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonChoice.is-active {
  background-color: rgba(138, 69, 36, 0.9);
}

.textBazar {
  width: 580px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.bazarSubComponent {
  margin-top: 20px;
  width: 580px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.block_container {
  margin-left: 10px;
  margin-bottom: 20px;
  text-align: left;
}

#bloc1 {
  display: block;
  width: 150px;
}

#bloc3 {
  transform: translate(0px, 20px);
  margin-left: 10px;
}

#bloc1,
#bloc2,
#bloc3 {
  display: inline-block;
}

.blockBonusBazar {
  display: inline-block;
}

.bazarBorderListing {
  width: 583px;

  border: 1px solid #efac71;
  display: inline-block;
  margin-bottom: 11px;
}

.bazarBorderListing:hover {
  max-width: 583px !important;
  outline: 2px solid #efac71;
}

.dinoBazar {
  vertical-align: top;
  margin-top: 3px;
  margin-left: 3px;
  margin-bottom: 2px;
  display: inline-block;
}

.dinoBazarListObjectsLeft {
  margin-top: 5px;
  margin-left: 4px;
  display: inline-block;
}

.dinoBazarObjectsGrid9x9 {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
}

.dinoBazarListObjectsMiddle {
  vertical-align: top;
  margin-top: 5px;
  margin-left: 4px;
  width: 89px;
  display: inline-block;
}

.dinoBazarListObjectsRight {
  vertical-align: top;
  margin-top: 5px;
  margin-left: 4px;
  width: 89px;
  display: inline-block;
}

.dinoPic {
  border: 1px solid #efac71;
}

.levelBazarDisplay {
  margin-left: 6px;
  margin-bottom: 6px;
}

.levelBazarDropdownDisplay {
  background-color: #f7dcb7;
  margin-left: 6px;
  margin-bottom: 6px;
}

.verticalBar {
  height: 102px;
  border-left: 1px solid #efac71;
}

.dinoBazarFiller {
  width: 293px;
  vertical-align: top;
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
}

.imgObjBazar {
  width: 89px;
  white-space: pre;
  vertical-align: top;
}

.dinoBazarHBar {
  margin-top: 2px;
  width: 583px;
  border-top: 1px solid #efac71;
}

.actualSeller {
  display: block;
  width: 275px;
  margin-left: 1px;
}

.numberFieldBazarBid {
  margin-left: 45px;
  transform: translate(0px, -3px);
  width: 60px;
  background-color: var(--backgroundcolor);
}

.buttonBidBazar {
  margin-left: 16px;
  transform: translate(0px, -3px);
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonBidBazar:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.youCurrentlyHave {
  width: 220px;
  transform: translate(0px, 21px);
  font-weight: bold;
  font-style: italic;
}

.youCurrentlyHaveS3 {
  width: 400px;
  font-weight: bold;
  font-style: italic;
}

.filters {
  width: 45px;
  transform: translate(366px, 36px);
  font-weight: bold;
  font-style: italic;
}

.noFlexTop {
  display: flex;
  vertical-align: top;
  flex-shrink: 0;
}

.noFlexTopImg {
  border: 2px solid #ba784b;
  height: 128px;
  width: 168px;
}

.textPantheon {
  width: 393px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: justify;
  padding-left: 10px;
}

.imgStarsPantheon {
  width: 100px;
  height: 500px;
}

.spacerA {
  margin-right: 9px;
}

.orderSigns {
  font-size: 10pt;
}

.orderSigns:hover {
  cursor: pointer;
  color: red;
}

.modifyOrder {
  margin-right: 4px;
}

.modifyOrder:hover {
  cursor: pointer;
  font-weight: bold;
}

.blockUpDown {
  width: 70px;
  margin: 0px;
  margin-top: 5px;
  padding-top: 0px;
}

.clock:hover {
  cursor: zoom-in;
  color: mediumpurple;
  font-size: 12pt;
}

.gridGenome {
  margin-top: 15px;
  justify-content: flex-start;
}

.resultGridDinoz {
  display: inline-block;
  margin-left: 5px;
  padding-top: 1px;
}

.promptName {
  padding: 30px;
  background-color: #fcead1;
  border: 5px solid #d28e51;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: justify;
  font-weight: bolder;
}

.btnOkPrompt {
  margin-left: 5px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.btnOkPrompt:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.buttonCreateClan {
  line-height: 10px;
  margin-bottom: 25px;
  margin-left: 180px;
  padding: 5px;
  height: 46px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.buttonCreateClan:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.inputClanName {
  width: 300px;
}

.inputClanDesc {
  resize: none;
  margin-left: 5px;
  width: 450px;
  height: 200px;
}

.messageBoxClan {
  resize: none;
  width: 570px;
  height: 200px;
}

.createClanButton {
  width: 150px;
  margin-left: 230px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.createClanButton:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.sendMsgClanButton {
  width: 577px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.sendMsgClanButton:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.clanPane {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.privatePageLabel {
  white-space: pre-line;
  margin-left: 10px;
  padding-bottom: 6px;
}

.privatePageLabel img {
  max-width: 97%;
}

.clickablePageFromClan {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #c37253;
  font-weight: bold;
  font-size: 10pt;
  padding-left: 6px;
  padding-bottom: 6px;
  border-collapse: collapse;
  text-decoration: underline;
}

.clickablePageFromClan:hover {
  color: #8a4524;
  cursor: pointer;
}

.totem {
  border: 2px solid #efac71;
}

.clanSection {
  padding-top: 5px;
}

.textTotem {
  display: inline-block;
  padding-left: 14px;
  width: 170px;
}

.statsClan {
  margin-left: 10px;
}

.pointsSummary {
  display: inline-table;
  background-color: #ffe4bf;
  border: 3px solid #efac71;
  width: 282px;
  margin-top: 15px;
  margin-left: 10px;
}

.msg {
  width: 573px;
  margin-left: 6px;
  margin-top: 4px;
  margin-bottom: 10px;
  background-color: #ffe4bf;
  border-bottom: 2px solid #efac71;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.headerClan {
  background-color: #efac71;
  color: white;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  height: 18px;
}

.clanMsgAuthor {
  float: left;
  position: relative;
  color: white;
  font-weight: bold;
}

.clanMsgDate {
  float: right;
  position: relative;
  color: white;
  font-weight: bold;
}

.clanMessageRender {
  white-space: pre-line;
  margin: 8px;
  width: 560px;
  word-wrap: break-word;
}

.editAndManage {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  color: #7e24e9;
  font-weight: bold;
  font-size: 10pt;
  border-collapse: collapse;
  text-decoration: underline;
  margin: 0px;
}

.editAndManage:hover {
  color: #8a4524;
  cursor: pointer;
}

.simpleTextArea {
  resize: none;
  width: 570px;
  height: 15px;
}

.bigTextArea {
  word-break: break-word;
  white-space: pre-line;
  resize: none;
  width: 570px;
  height: 225px;
}

.elemPagesClan {
  line-height: 10px;
  margin: 6px 0px 0px;
}

.recentClansTable {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
  border-bottom: 2px solid #efac71;
  border-collapse: separate;
  border-spacing: 3px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #ffe4c1;
}

.recentClansTable th {
  min-width: 100px;
  background-color: #efac71;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.recentClansTable td {
  line-height: 9px;
  text-align: left;
  border-bottom: 1px solid #f4d1a2;
  padding-left: 6px;
  padding-top: 3px;
}

.clanNameCl {
  min-width: 200px !important;
}

.publicClanMembersTable {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
  border-bottom: 2px solid #efac71;
  border-collapse: separate;
  border-spacing: 3px;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #ffe4c1;
}

.publicClanMembersTable th {
  background-color: #efac71;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
}

.publicClanMembersTable td {
  line-height: 9px;
  text-align: center;
  border-bottom: 1px solid #f4d1a2;
  padding-top: 3px;
}

.publicClanMembersTable td:first-child {
  text-align: left;
}

.blackTitle {
  margin-left: 5px;
  margin-right: 5px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-weight: bolder;
  text-align: left;
  color: black;
}

.clanPtsIcons {
  padding-top: 4px;
}

.telescopeList {
  line-height: 12px;
  margin: 14px;
}

.telescopeListDinoz {
  line-height: 12px;
  margin-left: 130px;
}

.minichampifuz {
  transform: translate(5px, 2px);
}

.recettes {
  text-align: right;
  margin: 20px;
}

.recetteElement {
  margin-left: 10px;
}

.recetteElementResult {
  display: inline-block;
  height: 40px;
  width: 75px;
  margin-left: 10px;
  background-color: #ffd49c;
}

.recetteElementResult:hover {
  display: inline-block;
  height: 40px;
  width: 75px;
  margin-left: 10px;
  background-color: #a18dc4;
  cursor: pointer;
}

.plusEquals {
  color: #c37253;
  margin-left: 10px;
  font-size: 16pt;
}

.irmaQtyCaverne {
  margin-left: 4px;
  margin-bottom: 12px;
}

.iconTotem {
  padding-left: 14px;
  padding-right: 6px;
  transform: translate(0px, 8px);
}

.nameTotem {
  border-bottom: 1px solid #efac71;
  color: #c37253;
  transform: translate(0px, -14px);
}

.possessionTotem {
  color: #303030;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 12pt;
  border-collapse: collapse;
}

.espaceNomTotem {
  padding-right: 3px;
}

.espaceNomTotemRewards {
  padding-right: 3px;
  color: black;
  font-weight: normal;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 11pt;
}

.totemSpace {
  height: 40px;
}

.titreObjetTotem {
  width: 164px;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  text-align: left;
  margin: 0px;
}

.offsetClanChest {
  margin-left: 34px;
}

.fusionWrapper {
  margin-top: 10px;
  margin-left: 95px;
  width: 400px;
  height: 200px;
  overflow: hidden;
}

.displayPeauDeVignes {
  height: 150px;
  width: 150px !important;
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
}

.clanTotemLinks {
  transform: translate(-6px, 0px);
}

.background_demon_object_won {
  display: flex;
  align-items: flex-start;
  width: 192px;
  height: 97px;
  background-image: url("background_fusion_small.png");
}

.demonCollectionObject {
  margin-top: 34px;
  margin-left: 80px;
}

/** TEMP CSS **/
.TEMP_mainContainer {
  margin: 0 13px 0 -13px;
}

.TEMP_mainContainer .digResult,
.TEMP_mainContainer .rockResult,
.TEMP_mainContainer .errorResult,
.TEMP_mainContainer .agilityResult,
.TEMP_mainContainer .successResult {
  transform: none;
}

.imgFactions {
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 2px solid #c37253;
  border-left: 2px solid #c37253;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  border-style: solid;
}

.clanChestItems {
  margin-top: 10px;
  border-bottom: 3px solid #efac71;
  border-top: 3px solid #efac71;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.nameClanChest {
  vertical-align: center;
  border-bottom: 1px solid #efac71;
  height: 45px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
}

.clanChestSuccess {
  color: black;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 510px;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
}

.clanChestError {
  color: red;
  min-height: 38px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 510px;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 18px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_error.gif);
}

.clanEnemy {
  margin-left: 12px;
  font-weight: bold;
  color: red;
}

.clanWarHelp {
  white-space: pre-wrap;
}

.rewardsFromMissions {
  display: inline-block;
}

.imageFromChocolateEgg {
  vertical-align: middle;
  margin: 4px;
}

.successResultChocolateEgg {
  line-height: 18px;
  color: black;
  transform: translate(-10px, 0px);
  height: 60px;
  background-color: #ffe4c1;
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 535px;
  padding-left: 50px;
  padding-right: 10px;
  font-weight: bold;
  clear: both;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  border-collapse: collapse;
  border: 2px solid #efac71;
  background-image: url(hist_default.gif);
  margin-bottom: 10px;
}

.cerbereTourneyPrizes {
  margin-left: 85px;
  white-space: pre-wrap;
}

.radioCasino {
  margin-top: 25px;
  margin-left: 70px;
  margin-bottom: 30px;
}

.ladderHalloween {
  width: 585px;
  height: 90px;
  background-image: url("ladder_halloween_2024.png");
  background-size: 585px;
}

.progressLineHalloween {
  max-width: 510px;
  position: absolute;
  transform: translate(22px, 38px);
  border: 6px solid #27273d;
  border-radius: 5px;
}

.textMerchantExchange {
  width: 585px;
  text-align: justify;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.rewardMerchant {
  margin-top: 20px;
  margin-left: 180px;
}

.noComp {
  margin-top: 5px;
  margin-left: 10px;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  font-style: italic;
}

.starBoutiqueDinoz {
  font-size: 7pt;
}

.dinozAliveInTourney {
  color: #c37253;
  font-weight: bold;
}

.dinozDeadInTourney {
  opacity: 45%;
  text-decoration-line: line-through;
}

.imgModeBtnSpacing {
  padding-left: 126px;
}

.actionModeBtnSpacing {
  padding-left: 125px;
}

.casinoChoice {
  padding: 10px;
}

.fisherRamenElementResult {
  display: inline-block;
  height: 35px;
  margin-left: 190px;
  background-color: #ffd49c;
}

.fisherRamenElementResult:hover {
  display: inline-block;
  height: 35px;
  margin-left: 190px;
  background-color: #a18dc4;
  cursor: pointer;
}

.message-bienvenue-popup {
  color: #623324;
  font-weight: bold;
  width:50%;
  height:50%;
  padding-left:25%;
  position: center;
  text-align: center;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
}

.message-bienvenue-popup button {
  line-height: 10px;
  padding: 5px;
  height: 50px;
  background-color: #d28e51;
  color: #fcead1;
  font-weight: bold;
  cursor: pointer;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.fightLifeContainer {
  position: absolute;
  z-index: 1;
  width: 7px;
  height: 130px;
  background-color: #451861;
  border-radius: 5px;
  margin-top: 45px;
}

.fightLifeContent {
  z-index: 1;
  width: 7px;
  background-color: #CC0000;
  border-radius: 5px;
}

.fightElementBackground {
  position: absolute;
  z-index: 1;
  height: 26px;
  width: 26px;
  background-color: #EDF6FF;
  border-radius: 50%;
}

.fightElementImg {
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
  margin-left: 3px;
  margin-top: 3px;
  z-index: 1;
}

.fightScore {
  position: absolute;
  z-index: 1;
  background-color: #9BC6F9;
  margin-top: 255px;
  padding-bottom: 10px;
  text-align: center;
  height: 38px;
  width: 115px;
  color: #3E9DFD;
  font-size: 35px;
}

.fightScoreLoader {
  z-index: 1;
  width: 115px;
  height: 115px;
  aspect-ratio: 1;
  display: grid;
  color: #3E9DFD;
  animation: fightScoreLoaderAnimation 2.5s infinite linear;
}
.fightScoreLoader::before,
.fightScoreLoader::after {
  content: "";
  grid-area: 1/1;
  background: repeating-conic-gradient(#9BC6F9 0 45deg,currentColor 0 90deg);
  -webkit-mask: radial-gradient(farthest-side,#9BC6F9 calc(100% - 3px),#9BC6F9 0);
  border-radius: 50%;
}
.fightScoreLoader::after {
  margin: 20%;
}
@keyframes fightScoreLoaderAnimation {
  100% {transform: rotate(1turn)}
}

.fightCircles {
  height: 150px;
  width: 150px;
  position: relative;
  
  > div {
    animation: fightCircleAnimation 1s infinite ease-out;
    background-color: dodgerblue;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  
  .fightCircle1 {
    animation-delay: 0.25s;    
  }
  .fightCircle2 {
    animation-delay: 0.5s; 
  }
  .fightCircle3 {
    animation-delay: 0.75s;
  }
}

.fightDrops {
  height: 50px;
  width: 110px;
  position: relative;
  
  .fightDrop {
    width: 10px;
    height: 10px;
    border-radius: 50% 0% 50% 50%;
    background: radial-gradient(circle, #000, #f00);
    background-size: 100% 150%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    animation: fightDropFall 2s ease-in infinite;
    opacity: .50;
  }
}

.fightProgressBarContainer {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 12px;
  margin-top: 6px;
  margin-left: 30px;
  background-color: #B2D3F7;
  border-radius: 5px;
}

.fightProgressBarCompleted {
  z-index: 1;
  height: 12px;
  background-color: #CC0000;
  border-radius: 5px;
}

.fightProgressBar {
  position: absolute;
  z-index: 1;
  background-color: "#B2D3F7";
  border-radius: 5px;
}

.fightProgressBar::before {
  position: absolute;
  height: 12px;
  border-radius: 5px;
  background-color: #CC0000;
  animation: fightProgressBar-anim 2s infinite;
  content: '';
  display: block;
}

@keyframes fightCircleAnimation {
  0% {
    opacity: .25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes fightDropFall {
  100% { top: 100%; }
}

@keyframes fightProgressBar-anim {
  0%   {
    width: 0%
  }
  100% {
    width: 100% 
  }
}